import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faChevronDown,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useRouter } from "next/router";
import { isPublicPage, userRole } from "@/utilities";
import { useAuth } from "@/hooks/auth";
import AccountMenu from "@/components/commonlayout/AccountMenu";
import { homeNavigation } from "@/constants";
import { MenuDropdown } from "../MenuDropdown";
import Image from "next/image";
import BannerHeader from "../BannerHeader";
import { useSelector } from "react-redux";

const Header = ({ isEngineer }) => {
    const router = useRouter();
    const { user } = useAuth({ middleware: "guest" });
    const { userIsCompany, userIsEngineer } = userRole(user);
    const { logout } = useAuth();

    const { hideBanner } = useSelector((store) => store.other);

    let module = "";
    if (userIsCompany) {
        module = "company";
    }

    if (userIsEngineer) {
        module = "engineer";
    }

    return (
        <div className="header">
            {process.env.NEXT_PUBLIC_TOP_BANNER_TEXT && (
                <div className="mx-auto">
                    {isPublicPage(router) && !hideBanner && <BannerHeader />}
                </div>
            )}
            <div className="mx-auto shadow">
                <Popover>
                    <div className="relative pt-4 pb-4 px-4 sm:px-4 lg:px-4">
                        <nav
                            className="relative z-50 items-center flex justify-between sm:h-10"
                            aria-label="Global"
                        >
                            <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                                <div className="flex items-center justify-between w-full md:w-auto">
                                    <Link
                                        href={`${module === "company"
                                                ? `/${module}/dashboard`
                                                : module === "engineer"
                                                    ? `/${module}/profile`
                                                    : "/"
                                            }`}
                                    >
                                        <a data-test-id="tacnique-logo">
                                            <span className="sr-only">
                                                Tacnique
                                            </span>
                                            <Image
                                                src={"/logos/tacnique-logo.svg"}
                                                alt="Tacnique Logo"
                                                width={142}
                                                height={40}
                                                loading="lazy"
                                            />
                                        </a>
                                    </Link>
                                    <div className="-mr-2 flex items-center md:hidden">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                            <span className="sr-only">
                                                Open main menu
                                            </span>
                                            <FontAwesomeIcon
                                                icon={faBars}
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>

                                <div className="hidden md:block md:ml-6 lg:ml-12 md:pr-4 md:space-x-4 lg:md:space-x-8">
                                    {homeNavigation.map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            {item.children ? (
                                                <MenuDropdown
                                                    item={item}
                                                    renderTrigger={() => (
                                                        <span
                                                            key={item.name}
                                                            onClick={() => {
                                                                if (item.href) {
                                                                    router.push(
                                                                        item.href
                                                                    );
                                                                }
                                                            }}
                                                            className={
                                                                item.links.includes(
                                                                    router.pathname
                                                                )
                                                                    ? "font-medium text-primary-700 hover:text-primary-900"
                                                                    : "font-medium text-gray-500 hover:text-gray-900 relative"
                                                            }
                                                            data-test-id={`nav-${item.name
                                                                .replace(
                                                                    " ",
                                                                    "-"
                                                                )
                                                                .toLowerCase()}`}
                                                        >
                                                            {item.name}
                                                            <FontAwesomeIcon
                                                                className="ml-2 font-sm "
                                                                icon={
                                                                    faChevronDown
                                                                }
                                                            />
                                                        </span>
                                                    )}
                                                    key={index}
                                                />
                                            ) : (
                                                <Link
                                                    href={item.href}
                                                    key={index}
                                                >
                                                    <a
                                                        type="button"
                                                        className={
                                                            item.links.includes(
                                                                router.pathname
                                                            )
                                                                ? "font-medium text-primary-700 hover:text-primary-900"
                                                                : "font-medium text-gray-500 hover:text-gray-900"
                                                        }
                                                        data-test-id={`nav-${item.name
                                                            .replace(" ", "-")
                                                            .toLowerCase()}`}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <AccountMenu
                                user={user}
                                logout={logout}
                                size="large"
                                check={false}
                                module={module}
                                organization={user?.organization}
                                isEngineer={isEngineer}
                                userRoles={user?.roles}
                            />
                        </nav>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-150 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
                        >
                            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                                <div className="px-5 pt-4 flex items-center justify-between">
                                    <div>
                                        <Image
                                            src="/logos/tacnique-logo.svg"
                                            alt="tacnique-logo"
                                            className="h-8 w-auto"
                                            loading="lazy"
                                        />
                                    </div>
                                    <div className="-mr-2">
                                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                                            <span className="sr-only">
                                                Close main menu
                                            </span>
                                            <FontAwesomeIcon
                                                icon={faXmark}
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                            />
                                        </Popover.Button>
                                    </div>
                                </div>
                                <div className="px-2 pt-2 pb-3 space-y-1">
                                    {homeNavigation.map((item, index) => (
                                        <React.Fragment key={item.name}>
                                            {item.children ? (
                                                <>
                                                    <Link
                                                        href={item.href}
                                                        key={index}
                                                    >
                                                        <a
                                                            className={
                                                                item.links.includes(
                                                                    router.pathname
                                                                )
                                                                    ? "block px-3 py-2 rounded-md text-base font-medium text-primary-700 hover:text-primary-900 hover:bg-primary-50"
                                                                    : "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                                            }
                                                            data-test-id={`nav-${item.name
                                                                .replace(
                                                                    " ",
                                                                    "-"
                                                                )
                                                                .toLowerCase()}`}
                                                        >
                                                            {item.name}
                                                        </a>
                                                    </Link>
                                                    {item.children.map(
                                                        (child, childIndex) => {
                                                            return (
                                                                <Link
                                                                    href={
                                                                        child.href
                                                                    }
                                                                    key={
                                                                        childIndex
                                                                    }
                                                                >
                                                                    <a
                                                                        key={
                                                                            child.name
                                                                        }
                                                                        className={
                                                                            child.links.includes(
                                                                                router.pathname
                                                                            )
                                                                                ? "block px-3 py-2 rounded-md text-base font-medium text-primary-700 hover:text-primary-900 hover:bg-primary-50"
                                                                                : "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                                                        }
                                                                        data-test-id={`nav-${child.name
                                                                            .replace(
                                                                                " ",
                                                                                "-"
                                                                            )
                                                                            .toLowerCase()}`}
                                                                    >
                                                                        {
                                                                            child.name
                                                                        }
                                                                    </a>
                                                                </Link>
                                                            );
                                                        }
                                                    )}
                                                </>
                                            ) : (
                                                <Link
                                                    href={item.href}
                                                    key={index}
                                                >
                                                    <a
                                                        className={
                                                            item.links.includes(
                                                                router.pathname
                                                            )
                                                                ? "block px-3 py-2 rounded-md text-base font-medium text-primary-700 hover:text-primary-900 hover:bg-primary-50"
                                                                : "block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                                                        }
                                                        data-test-id={`nav-${item.name
                                                            .replace(" ", "-")
                                                            .toLowerCase()}`}
                                                    >
                                                        {item.name}
                                                    </a>
                                                </Link>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            <AccountMenu
                                size="small"
                                module={module}
                                user={user}
                                check={false}
                                logout={logout}
                                isEngineer={isEngineer}
                                userRoles={user?.roles}
                            />
                        </Popover.Panel>
                    </Transition>
                </Popover>
            </div>
        </div>
    );
};

export default Header;
